<template>
  <div class="page-dashboard">
    <!--begin::Dashboard-->

    <div class="card card-custom gutter-b dashboard-farm-controller">
<!--      <div class="card-header border-0">-->
<!--        <t-farmon-table-header></t-farmon-table-header>-->
<!--      </div>-->

      <div class="card-body p-1">
      <!--begin::controller-tabs-->
        <div class="controller-tabs">
          <b-tabs
              nav-class="pt-5"
          >
            <b-tab v-for="(item, i) in connects" :key="i" :active="i === idx" @click="changeConnect(i)">
              <template slot="title">
                <span class="nav-text font-weight-bolder font-size-h4">{{ item.connectNm }}</span>
              </template>
            </b-tab>

            <div class="card-body pt-0">
              <div class="row mb-5">
                <div class="form-inline col-xxl-3 col-lg-4 col-sm-12 mb-3">
                  <label class="text font-size-h6 font-weight-bold mr-5">{{ i18n.startDate }} </label>
                  <b-form-datepicker
                      v-model="startYmd"
                      :locale="locale"
                      class="farm-custom-datepicker"
                      placeholder=""
                      style="width: 200px"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      @input="changeDate"
                  ></b-form-datepicker>
                </div>

                <div class="form-inline col-xxl-3 col-lg-4 col-sm-12 mb-3">
                  <label class="text font-size-h6 font-weight-bold mr-5">{{ i18n.endDate }} </label>
                  <b-form-datepicker
                      v-model="endYmd"
                      :locale="locale"
                      class="farm-custom-datepicker"
                      placeholder=""
                      style="width: 200px"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      @input="changeDate"
                  ></b-form-datepicker>
                </div>
              </div>

              <div class="d-flex align-items-start flex-wrap">
                <b-list-group horizontal class="mb-5" style="margin-right: auto;">
                  <b-list-group-item
                    v-for="(dateRange) in fixedDateRange.dateRanges"
                    button
                    :key="dateRange.key"
                    :active="fixedDateRange.activeDateRange === dateRange.key"
                    @click="onFixedDateRangeChanged(dateRange.key)"
                  >
                    {{ dateRange.date }}
                  </b-list-group-item>
                </b-list-group>

                <div class="p-4" >
                  <b-button class="btn btn-dark rounded" size="sm" @click="showThreshold()">
                   <span class='icon-xl-ms fas fa-info-circle mr-2'></span>
				            <span>{{i18n.thresholdcheck}}</span>
                  </b-button>
                </div>
              </div>

              <div class="row">
              <div v-for="(categorie,idx) in categories" :key="idx" class="p-6 col-xxl-6">
                <div class="d-flex align-items-start justify-content-between flex-grow-1 p-6 rounded" v-bind:class="'controller-widget8'" style="backgroundColor: #F7F3FE;">
                  <span class="symbol symbol-50 symbol-white mr-2">
                    <span class="symbol-label">
                      <span v-if="idx == 0" class="svg-icon svg-icon-xxl svg-icon-primary">
                        <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Weather/Temperature-empty.svg" />
                      </span>
                      <span v-if="idx == 1" class="svg-icon svg-icon-xxl svg-icon-info">
                        <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Design/Bucket.svg" />
                      </span>
                      <span v-if="idx == 2" class="svg-icon svg-icon-xxl svg-icon-danger">
                        <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Design/Mask.svg" />
                      </span>
                      <span v-if="idx == 3" class="svg-icon svg-icon-xxl svg-icon-success">
                        <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Design/Eraser.svg" />
                      </span>
                      <span v-if="idx == 4" class="svg-icon svg-icon-xxl svg-icon-dark">
                        <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Weather/Fog.svg" />
                      </span>
                    </span>
                  </span>
                  <div class="d-flex flex-column text-right">
                    <span class="font-size-h4 font-weight-bold">{{categories[idx]}}</span>
                  </div>
                </div>
                <monitoring-alert-time-chart-widget
                  :chartDatas="chartDatas[idx]"
                  :pieChartDatas='pieChartDatas[idx]'
                />
              </div>
              </div>

            </div>

          </b-tabs>
        </div>
        <!--end::controller-tabs-->
      </div>

      <!-- begin:thresholdModal -->
			<b-modal
			v-model="thresholdModal"
			size="xxl"
			hide-footer
			hide-header
			>
			<div class="card card-custom card-stretch">
			<!--begin::Header-->
			<div class="card-header border-0 align-items-center">
				<h3 class="card-title align-items-start flex-column">
				<span class="title font-weight-bold">{{i18n.thresholdInfo}}</span>
				</h3>
			</div>
			<!--end::Header-->
			<!-- begin::Body -->
			<div class="card-body pt-5 overflow-auto">
				<table v-for="(cropState,i) in cropStates" :key="i" class="table table-vertical-center dtr-inline text-center table-condensed table-fixed">
					<colgroup>
					<col style="width:100px;" />
					<col style="width:100px;" />
					<col style="width:auto;" />
					<col style="width:auto;" />
					<col style="width:auto;" />
					<col style="width:auto;" />
					<col style="width:auto;" />
					<col style="width:auto;" />
					<col style="width:auto;" />
					</colgroup>
					<thead class="thead-light">
					<tr role="row">
					<th rowspan="2" colspan="2" class="rounded"><h4><i :class="`fas ${thresholdIcon[i]} pr-2 text-dark`"></i>{{cropState}}</h4></th>
					<th rowspan="2" colspan="1">{{i18n.targetValue}}</th>
					<th rowspan="1" colspan="2">{{i18n.thresholdManagement}}</th>
					<th rowspan="1" colspan="2">{{i18n.thresholdAbnormalAlarm}}</th>
					<th rowspan="1" colspan="2">{{i18n.thresholdPhysiologicalDisorder}}</th>
					</tr>
					<tr role="row">
					<th rowspan="1" colspan="1">{{i18n.maximum}}</th>
					<th rowspan="1" colspan="1">{{i18n.minimum}}</th>
					<th rowspan="1" colspan="1">{{i18n.maximum}}</th>
					<th rowspan="1" colspan="1">{{i18n.minimum}}</th>
					<th rowspan="1" colspan="1">{{i18n.maximum}}</th>
					<th rowspan="1" colspan="1">{{i18n.minimum}}</th>
					</tr>
					</thead>
					<tbody v-for="(threshold,j) in 5" :key="j">
					<tr v-if="j<3" role="row">
						<td rowspan="2" colspan="1" class="font-weight-bold">{{thresholdHeadItems[j]}}</td>
						<td rowspan="1" colspan="1" class="font-weight-bold">{{i18n.day}}</td>
						<td v-for="(baselineVal,k) in 7" :key=k rowspan="1" colspan="1">
						<span>{{j==0 ? baselineItems.tempDay[baselineCropStates[i]][k]: j==1 ? baselineItems.humDay[baselineCropStates[i]][k] : baselineItems.CO2Day[baselineCropStates[i]][k]}}</span>
						</td>
					</tr>
					<tr v-if="j<3" role="row">
						<td rowspan="1" colspan="1" class="font-weight-bold">{{i18n.night}}</td>
						<td v-for="(baselineVal,k) in 7" :key=k rowspan="1" colspan="1">
						<span>{{j==0 ? baselineItems.tempNight[baselineCropStates[i]][k]: j==1 ? baselineItems.humNight[baselineCropStates[i]][k] : baselineItems.CO2Night[baselineCropStates[i]][k]}}</span>
						</td>
					</tr>
					<tr v-if="j>=3" role="row">
						<td rowspan="1" colspan="2" class="font-weight-bold">{{thresholdHeadItems[j]}}</td>
						<td v-for="(baselineVal,k) in 7" :key=k rowspan="1" colspan="1">
						<span>{{(j==3 ? baselineItems.EC[baselineCropStates[i]][k] : baselineItems.PH[baselineCropStates[i]][k])}}</span>
						</td>
					</tr>
					</tbody>
				</table>
			</div>
			<!-- end::Body -->
			<!--begin::Footer-->
				<div class="card-footer">
					<b-button variant="secondary" size="lg" class="ml-3 float-right" @click="thresholdModal = false"><span>{{i18n.close}}</span></b-button>
				</div>
				<!--end::Footer-->
			</div>
			</b-modal>
			<!-- end::thresholdModal -->

    </div>
    <!--end::Dashboard-->
  </div>
</template>
<script>
import MonitoringAlertTimeChartWidget from '@/components/MonitoringAlertTimeChartWidget';
import {
  collectionsCheck,
  getItem,
  getItems,
  getPaging,
  lengthCheck,
  todayToDate,
  syncObj,
} from '@/core/services/funcs';
import {ACT_GET_MY_CONNECTS, ACT_GET_ALERT_LOGS_STATICS, ACT_GET_FARM_THRESHOLD} from '@/core/services/variable';
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import {ACT_ADD_ALERT} from '@/core/services/store/alert.module';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "AlertLogTimeSeries",
  components: {
    MonitoringAlertTimeChartWidget,
  },
  beforeMount() {
    const today = todayToDate('yyyy-MM-dd');
    if(Object.keys(i18n._localeChainCache)[0] == 'en'){
      this.locale = 'en'
    }

    // 그래프 시작일을 일주일전으로 설정
    let currentDate = new Date();
    const startDate = new Date(currentDate.setDate(currentDate.getDate() - 7 ));
    const startDateUTC = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));

    this.startYmd = startDateUTC.toISOString().slice(0, 10)
    this.endYmd = today

    if (this.$route.query.startDate != null && this.$route.query.startDate != '')
        this.startYmd = this.$route.query.startDate
    if (this.$route.query.endDate != null && this.$route.query.endDate != '')
        this.endYmd = this.$route.query.endDate

    this.$store.dispatch(ACT_GET_MY_CONNECTS).then(resp => {
      if(lengthCheck(resp)){
        const item = getItem(resp)
        if(collectionsCheck(item.farmConnects, 'seq')) {
          this.fno = item.fno;
          this.connects = item.farmConnects.filter(connect => connect.internetConnectYn == 'Y');
          this.systemId = this.connects[0].systemId;
          this.getStatusLogs();
        }
      }
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: i18n.t('topNavBar.farm.info'), icon: 'flaticon2-graph icon-lg' }, { title: i18n.t('topNavBar.farm.alertLogTimeSeriesInfo') }]);
    window.scrollTo(0,0);
  },
  data() {
    return {
      fno: 0,
      idx: 0,
      connects: [],
      connect: {},
      systemId: '',

      categories: [
        `${i18n.t('farm.alertLogTimeSeries.temperature')}`,
        `${i18n.t('farm.alertLogTimeSeries.humidity')}`,
        `${i18n.t('farm.alertLogTimeSeries.CO2')}`,
        `${i18n.t('farm.alertLogTimeSeries.EC')}`,
        `${i18n.t('farm.alertLogTimeSeries.pH')}`,
      ],
      chartDatas :  [],
      pieChartDatas: [[0,0,0],[0,0,0],[0,0,0],[0,0,0],[0,0,0]],

      statusInterval: null,

      startYmd: '',
      endYmd: '',
      pageNo: 1,
      hasMore: false,
      loading: false,
      i18n: {
        startDate: i18n.t('farm.alertLogTimeSeries.startDate'),
        endDate: i18n.t('farm.alertLogTimeSeries.endDate'),
        management: i18n.t('farm.alertLogTimeSeries.management'),
        abnormalAlarm: i18n.t('farm.alertLogTimeSeries.abnormalAlarm'),
        physiologicalDisorder: i18n.t('farm.alertLogTimeSeries.physiologicalDisorder'),
        setEndDate: i18n.t('farm.alertLogTimeSeries.setEndDate'),
        threeMonth: i18n.t('farm.alertLogTimeSeries.threeMonth'),
        thresholdcheck: i18n.t('farm.alertLogTimeSeries.thresholdcheck'),
        thresholdInfo: i18n.t('farm.alertLogTimeSeries.threshold.thresholdInfo'),
        targetValue:i18n.t('farm.alertLogTimeSeries.threshold.targetValue'),
        thresholdManagement: i18n.t('farm.alertLogTimeSeries.threshold.management'),
        thresholdAbnormalAlarm: i18n.t('farm.alertLogTimeSeries.threshold.abnormalAlarm'),
        thresholdPhysiologicalDisorder: i18n.t('farm.alertLogTimeSeries.threshold.physiologicalDisorder'),
        maximum: i18n.t('farm.alertLogTimeSeries.threshold.maximum'),
        minimum: i18n.t('farm.alertLogTimeSeries.threshold.minimum'),
        day: i18n.t('farm.alertLogTimeSeries.threshold.day'),
        night: i18n.t('farm.alertLogTimeSeries.threshold.night'),
        close: i18n.t('farm.alertLogTimeSeries.threshold.close'),
      },
      fixedDateRange: {
        activeDateRange: 'week',
        dateRanges: [
          { key: 'day', date: i18n.t('farm.timeseries.day') },
          { key: 'week', date: i18n.t('farm.timeseries.week') },
          { key: 'month', date: i18n.t('farm.timeseries.month') }
        ]
      },
      locale: 'ko',

      thresholdModal: false,
	  thresholdIcon: ['fa-spa','fa-seedling', 'fa-leaf'],
	  thresholdHeadItems: [i18n.t('farm.alertLogTimeSeries.threshold.temp'),i18n.t('farm.alertLogTimeSeries.threshold.hum'),'CO2 (ppm)', 'EC (dS/m)', 'pH'],
	  baselineCropStates: ['seedling','transplant','lastfeed'],
	  cropStates: [i18n.t('farm.alertLogTimeSeries.threshold.seedling'), i18n.t('farm.alertLogTimeSeries.threshold.transplant'), i18n.t('farm.alertLogTimeSeries.threshold.lastfeed')],
	  baselineItems:{
			tempDay: {seedling:[22,23,21,25,19,27,10], transplant:[22,23,21,25,19,27,10], lastfeed:[22,23,21,25,19,27,10]},
			tempNight: {seedling:[18,20,17,22,15,27,10], transplant:[18,20,17,22,15,27,10],lastfeed:[18,20,17,22,15,27,10]},
			humDay: {seedling:[70,80,60,100,50,100,20], transplant:[70,80,60,100,50,100,20], lastfeed:[70,80,60,100,50,100,20]},
			humNight: {seedling:[80,90,70,100,50,100,20], transplant:[80,90,70,100,50,100,20], lastfeed:[80,90,70,100,50,100,20]},
			CO2Day:{seedling:[900,1000,800,1200,600,'-','-'], transplant:[900,1000,800,1200,600,'-','-'], lastfeed:[900,1000,800,1200,500,'-','-']},
			CO2Night:{seedling:[900,1000,800,1200,600,'-','-'], transplant:[900,1000,800,1200,600,'-','-'], lastfeed:[900,1000,800,1200,500,'-','-']},
			EC: {seedling:[1.2,1.7,1.5,1.8,1.4,4.0,0.8], transplant:[1.7,1.9,1.7,2.0,1.6,4.0,0.8], lastfeed:[2.3,2.5,2.0,2.7,1.8,4.0,0.8]},
			PH: {seedling:[6.1,6.2,5.8,6.5,5.5,7.5,5.0], transplant:[6.1,6.2,5.8,6.5,5.5,7.5,5.0], lastfeed:[6,6.2,5.8,6.5,5.5,7.5,5.0]},
		},
    seedEcMax: 1.70,
    seedEcMin: 1.50,
    seedEcLimithigh: 1.80,
    seedEcLimitlow: 1.40,
    seedEcUrgenthigh: 4.00,
    seedEcUrgentlow: 0.80,
    transplantEcMax: 1.90,
    transplantEcMin: 1.70,
    transplantEcLimithigh: 2.00,
    transplantEcLimitlow: 1.60,
    transplantEcUrgenthigh: 4.00,
    transplantEcUrgentlow: 0.80,
    ecMax: 2.50,
    ecMin: 2.00,
    ecLimithigh: 2.70,
    ecLimitlow: 1.80,
    ecUrgenthigh: 4.00,
    ecUrgentlow: 0.80,
    phMax: 6.20,
    phMin: 5.80,
    phLimithigh: 6.50,
    phLimitlow: 5.50,
    phUrgenthigh: 7.50,
    phUrgentlow: 5.00,
    co2DayMax: 1000,
    co2DayMin: 800,
    co2DayLimithigh: 1200,
    co2DayLimitlow: 500,
    co2DayUrgenthigh: 0,
    co2DayUrgentlow: 0,
    co2NightMax: 1000,
    co2NightMin: 800,
    co2NightLimithigh: 1200,
    co2NightLimitlow: 500,
    co2NightUrgenthigh: 0,
    co2NightUrgentlow: 0,
    tempDayMax: 23,
    tempDayMin: 21,
    tempDayLimithigh: 25,
    tempDayLimitlow: 19,
    tempDayUrgenthigh: 27,
    tempDayUrgentlow: 10,
    tempNightMax: 20,
    tempNightMin: 17,
    tempNightLimithigh: 22,
    tempNightLimitlow: 15,
    tempNightUrgenthigh: 27,
    tempNightUrgentlow: 10,
    humidityDayMax: 80,
    humidityDayMin: 60,
    humidityDayLimithigh: 100,
    humidityDayLimitlow: 50,
    humidityDayUrgenthigh: 100,
    humidityDayUrgentlow: 20,
    humidityNightMax: 90,
    humidityNightMin: 70,
    humidityNightLimithigh: 100,
    humidityNightLimitlow: 50,
    humidityNightUrgenthigh: 100,
    humidityNightUrgentlow: 20,
    waterTempMax: 0,
    waterTempMin: 0,
    waterTempLimithigh: 0,
    waterTempLimitlow: 0,
    waterTempUrgenthigh: 0,
    waterTempUrgentlow: 0,
    }
  },
  methods: {
    getStatusLogs(){
      if(this.systemId){
        this.loading = true;

          this.$store.dispatch(ACT_GET_ALERT_LOGS_STATICS, {fno: this.fno, systemId: this.systemId, startYmd: this.startYmd.replace(/-/g, ''), endYmd: this.endYmd.replace(/-/g, ''), pageNo: this.pageNo, pageSize: 1000})
          .then((logs) => {
          if(lengthCheck(logs)) {
            this.setLineChartDate(getItems(logs));
            this.hasMore = getPaging(logs).hasMore;
          }else{
            this.hasMore = false;
          }
          this.loading = false;
        }).catch(e => {
          console.error(e)
          this.clearStatusInterval();
          this.hasMore = false;

          this.loading = false;
        });

      }
    },
    setLineChartDate(alertLogs){
      this.chartDatas = []
      this.pieChartDatas = [[0,0,0],[0,0,0],[0,0,0],[0,0,0],[0,0,0]]

      for(let i=0; i< 5; i++){
        this.chartDatas.push({
        physiologicalDisorder: {
					title: `${this.i18n.physiologicalDisorder}`,
					color: '#C47FC7',
					chartType: 'column',
					value: []
				},
        abnormalAlarm: {
					title: `${this.i18n.abnormalAlarm}`,
					color: '#E8A0BF',
					chartType: 'column',
					value: []
				},
				management: {
					title: `${this.i18n.management}`,
					color: '#FCCECA',
					chartType: 'column',
					value: []
				}
			})
      }

      alertLogs.forEach(alertLog => {
        const dt = alertLog.createYmd.split('-')
        const key = new Date(dt[0],dt[1]-1,dt[2])
          this.chartDatas[0].management.value.push([key, alertLog.tempMax + alertLog.tempMin])
          this.chartDatas[0].abnormalAlarm.value.push([key, alertLog.tempLimithigh + alertLog.tempLimitlow])
          this.chartDatas[0].physiologicalDisorder.value.push([key, alertLog.tempUrgenthigh + alertLog.tempUrgentlow])
          this.pieChartDatas[0][0] += alertLog.tempMax + alertLog.tempMin
          this.pieChartDatas[0][1] += alertLog.tempLimithigh + alertLog.tempLimitlow
          this.pieChartDatas[0][2] += alertLog.tempUrgenthigh + alertLog.tempUrgentlow

          this.chartDatas[1].management.value.push([key, alertLog.humidityMax + alertLog.humidityMin])
          this.chartDatas[1].abnormalAlarm.value.push([key, alertLog.humidityLimithigh + alertLog.humidityLimitlow])
          this.chartDatas[1].physiologicalDisorder.value.push([key, alertLog.humidityUrgenthigh + alertLog.humidityUrgentlow])
          this.pieChartDatas[1][0] += alertLog.humidityMax + alertLog.humidityMin
          this.pieChartDatas[1][1] += alertLog.humidityLimithigh + alertLog.humidityLimitlow
          this.pieChartDatas[1][2] += alertLog.humidityUrgenthigh + alertLog.humidityUrgentlow


          this.chartDatas[2].management.value.push([key, alertLog.co2Max + alertLog.co2Min])
          this.chartDatas[2].abnormalAlarm.value.push([key, alertLog.co2Limithigh + alertLog.co2Limitlow])
          this.chartDatas[2].physiologicalDisorder.value.push([key, alertLog.co2Urgenthigh + alertLog.co2Urgentlow])
          this.pieChartDatas[2][0] += alertLog.co2Max + alertLog.co2Min
          this.pieChartDatas[2][1] += alertLog.co2Limithigh + alertLog.co2Limitlow
          this.pieChartDatas[2][2] += alertLog.co2Urgenthigh + alertLog.co2Urgentlow


          this.chartDatas[3].management.value.push([key, alertLog.ecMax + alertLog.ecMin])
          this.chartDatas[3].abnormalAlarm.value.push([key, alertLog.ecLimithigh + alertLog.ecLimitlow])
          this.chartDatas[3].physiologicalDisorder.value.push([key, alertLog.ecUrgenthigh + alertLog.ecUrgentlow])
          this.pieChartDatas[3][0] += alertLog.ecMax + alertLog.ecMin
          this.pieChartDatas[3][1] += alertLog.ecLimithigh + alertLog.ecLimitlow
          this.pieChartDatas[3][2] += alertLog.ecUrgenthigh + alertLog.ecUrgentlow

          this.chartDatas[4].management.value.push([key, alertLog.phMax + alertLog.phMin])
          this.chartDatas[4].abnormalAlarm.value.push([key, alertLog.phLimithigh + alertLog.phLimitlow])
          this.chartDatas[4].physiologicalDisorder.value.push([key, alertLog.phUrgenthigh + alertLog.phUrgentlow])
          this.pieChartDatas[4][0] += alertLog.phMax + alertLog.phMin
          this.pieChartDatas[4][1] += alertLog.phLimithigh + alertLog.phLimitlow
          this.pieChartDatas[4][2] += alertLog.phUrgenthigh + alertLog.phUrgentlow
      })
    },
    changeConnect(i){
      if(this.startYmd.replace(/-/g, '') > this.endYmd.replace(/-/g, '')) {
        this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.setEndDate});
        return;
      }
      const date1 = new Date(this.startYmd)
      const date2 = new Date(this.endYmd)
      const diffDate = date1.getTime() - date2.getTime()
      const dateDays = Math.abs(diffDate / (1000 * 3600 * 24))
      if(dateDays>91){
        this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.threeMonth});
        return;
      }
      this.idx = i;
      this.pageNo = 1;
      if(this.connects[i] && this.connects[i].systemId) {
        this.connect = this.connects[i];
        this.systemId = this.connects[i].systemId;
        this.setStatusInterval();
      }else{
        this.systemId = '';
        this.connect = this.connects[i];
        this.clearStatusInterval();
      }
    },
    setStatusInterval(){
      this.clearStatusInterval();
      this.getStatusLogs();

      this.statusInterval = setInterval(() => {
        this.getStatusLogs()
      }, 60000);
    },
    clearStatusInterval(){
      if(this.statusInterval) {
        clearInterval(this.statusInterval);
        this.statusInterval = null;
      }
    },
    changeDate(){
      if(this.startYmd && this.endYmd){
        if(this.startYmd.replace(/-/g, '') > this.endYmd.replace(/-/g, '')) {
          this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.setEndDate});
          return;
        }
        const date1 = new Date(this.startYmd)
        const date2 = new Date(this.endYmd)
        const diffDate = date1.getTime() - date2.getTime()
        const dateDays = Math.abs(diffDate / (1000 * 3600 * 24))
        if(dateDays>91){
          this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.threeMonth});
          return;
        }
        this.pageNo = 1;
        this.fixedDateRange.activeDateRange = 'none'
        this.setStatusInterval();
      }
    },
    prevPage(){
      if(this.pageNo > 1){
        this.pageNo--;
        this.setStatusInterval();
      }
    },
    nextPage(){
      if(this.hasMore){
        this.pageNo++;
        this.setStatusInterval();
      }
    },
    onFixedDateRangeChanged(dateRange) {
      if (dateRange === this.fixedDateRange.dateRanges[0].key) {
        this.changeFixedDate(1)
      } else if (dateRange === this.fixedDateRange.dateRanges[1].key) {
        this.changeFixedDate(7)
      } else if (dateRange === this.fixedDateRange.dateRanges[2].key) {
        this.changeFixedMonth(1)
      }

      this.fixedDateRange.activeDateRange = dateRange
      this.setStatusInterval();
    },
    changeFixedDate(day) {
      let currentDate = new Date();
      const startDate = new Date(currentDate.setDate(currentDate.getDate() - day ));
      const startDateUTC = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
      this.startYmd = startDateUTC.toISOString().slice(0, 10)
      this.endYmd = new Date().toISOString().slice(0, 10);
    },
    changeFixedMonth(month) {
      let currentDate = new Date();
      const startDate = new Date(currentDate.setMonth(currentDate.getMonth() - month ));
      const startDateUTC = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
      this.startYmd = startDateUTC.toISOString().slice(0, 10)
      this.endYmd = new Date().toISOString().slice(0, 10);
    },
    getDateDiff(date1, date2, kind) {
      let diff = Math.floor((date1.getTime() - date2.getTime()) / (1000 * 60 * 60 * 24));
      if (kind === "m")
        diff = diff / 30;
      else if (kind === "y")
        diff = diff / 30 / 12;

      return diff;
    },
    showThreshold(){
      this.thresholdModal = true

    this.$store.dispatch(ACT_GET_FARM_THRESHOLD, this.fno).then(resp =>{
      if(!(resp.items.length == 0)){
        syncObj(this, resp.items[0])
        this.setBaseline()
      }else{
        this.$store.dispatch(ACT_GET_FARM_THRESHOLD, 0).then(baselineResp =>{
        let baselineItems = getItem(baselineResp)
        syncObj(this, baselineItems)
        this.setBaseline()
        })
      }
    })
    },
    setBaseline(){
      this.baselineItems = {
        tempDay: {seedling:[(this.tempDayMax+this.tempDayMin)/2,this.tempDayMin,this.tempDayMax,this.tempDayLimitlow,this.tempDayLimithigh,this.tempDayUrgentlow,this.tempDayUrgenthigh],
                transplant:[(this.tempDayMax+this.tempDayMin)/2,this.tempDayMin,this.tempDayMax,this.tempDayLimitlow,this.tempDayLimithigh,this.tempDayUrgentlow,this.tempDayUrgenthigh],
                lastfeed:[(this.tempDayMax+this.tempDayMin)/2,this.tempDayMin,this.tempDayMax,this.tempDayLimitlow,this.tempDayLimithigh,this.tempDayUrgentlow,this.tempDayUrgenthigh]},
        tempNight: {seedling:[(this.tempNightMax+this.tempNightMin)/2,this.tempNightMin,this.tempNightMax,this.tempNightLimitlow,this.tempNightLimithigh,this.tempNightUrgentlow,this.tempNightUrgenthigh],
                    transplant:[(this.tempNightMax+this.tempNightMin)/2,this.tempNightMin,this.tempNightMax,this.tempNightLimitlow,this.tempNightLimithigh,this.tempNightUrgentlow,this.tempNightUrgenthigh],
                    lastfeed:[(this.tempNightMax+this.tempNightMin)/2,this.tempNightMin,this.tempNightMax,this.tempNightLimitlow,this.tempNightLimithigh,this.tempNightUrgentlow,this.tempNightUrgenthigh]},
        humDay: {seedling:[(this.humidityDayMax+this.humidityDayMin)/2,this.humidityDayMin,this.humidityDayMax,this.humidityDayLimitlow,this.humidityDayLimithigh,this.humidityDayUrgentlow,this.humidityDayUrgenthigh],
                transplant:[(this.humidityDayMax+this.humidityDayMin)/2,this.humidityDayMin,this.humidityDayMax,this.humidityDayLimitlow,this.humidityDayLimithigh,this.humidityDayUrgentlow,this.humidityDayUrgenthigh],
                lastfeed:[(this.humidityDayMax+this.humidityDayMin)/2,this.humidityDayMin,this.humidityDayMax,this.humidityDayLimitlow,this.humidityDayLimithigh,this.humidityDayUrgentlow,this.humidityDayUrgenthigh]},
        humNight: {seedling:[(this.humidityNightMax+this.humidityNightMin)/2,this.humidityNightMin,this.humidityNightMax,this.humidityNightLimitlow,this.humidityNightLimithigh,this.humidityNightUrgentlow,this.humidityNightUrgenthigh],
                transplant:[(this.humidityNightMax+this.humidityNightMin)/2,this.humidityNightMin,this.humidityNightMax,this.humidityNightLimitlow,this.humidityNightLimithigh,this.humidityNightUrgentlow,this.humidityNightUrgenthigh],
                lastfeed:[(this.humidityNightMax+this.humidityNightMin)/2,this.humidityNightMin,this.humidityNightMax,this.humidityNightLimitlow,this.humidityNightLimithigh,this.humidityNightUrgentlow,this.humidityNightUrgenthigh]},
        CO2Day:{seedling:[(this.co2DayMax+this.co2DayMin)/2,this.co2DayMin,this.co2DayMax,this.co2DayLimitlow,this.co2DayLimithigh,this.co2DayUrgentlow,this.co2DayUrgenthigh],
            transplant:[(this.co2DayMax+this.co2DayMin)/2,this.co2DayMin,this.co2DayMax,this.co2DayLimitlow,this.co2DayLimithigh,this.co2DayUrgentlow,this.co2DayUrgenthigh],
            lastfeed:[(this.co2DayMax+this.co2DayMin)/2,this.co2DayMin,this.co2DayMax,this.co2DayLimitlow,this.co2DayLimithigh,this.co2DayUrgentlow,this.co2DayUrgenthigh]},
        CO2Night:{seedling:[(this.co2NightMax+this.co2NightMin)/2,this.co2NightMin,this.co2NightMax,this.co2NightLimitlow,this.co2NightLimithigh,this.co2NightUrgentlow,this.co2NightUrgenthigh],
            transplant:[(this.co2NightMax+this.co2NightMin)/2,this.co2NightMin,this.co2NightMax,this.co2NightLimitlow,this.co2NightLimithigh,this.co2NightUrgentlow,this.co2NightUrgenthigh],
            lastfeed:[(this.co2NightMax+this.co2NightMin)/2,this.co2NightMin,this.co2NightMax,this.co2NightLimitlow,this.co2NightLimithigh,this.co2NightUrgentlow,this.co2NightUrgenthigh]},
        EC: {seedling:[(this.seedEcMax+this.seedEcMin)/2,this.seedEcMin,this.seedEcMax,this.seedEcLimitlow,this.seedEcLimithigh,this.seedEcUrgentlow,this.seedEcUrgenthigh],
            transplant:[((this.transplantEcMax+this.transplantEcMin)/2).toFixed(1),this.transplantEcMin,this.transplantEcMax,this.transplantEcLimitlow,this.transplantEcLimithigh,this.transplantEcUrgentlow,this.transplantEcUrgenthigh],
            lastfeed:[(this.ecMax+this.ecMin)/2,this.ecMin,this.ecMax,this.ecLimitlow,this.ecLimithigh,this.ecUrgentlow,this.ecUrgenthigh]},
        PH: {seedling:[(this.phMax+this.phMin)/2,this.phMin,this.phMax,this.phLimitlow,this.phLimithigh,this.phUrgentlow,this.phUrgenthigh],
            transplant:[(this.phMax+this.phMin)/2,this.phMin,this.phMax,this.phLimitlow,this.phLimithigh,this.phUrgentlow,this.phUrgenthigh],
            lastfeed:[(this.phMax+this.phMin)/2,this.phMin,this.phMax,this.phLimitlow,this.phLimithigh,this.phUrgentlow,this.phUrgenthigh]},
			}
    },
  }
};
</script>

<style>

svg{
  fill: dark;
}
</style>