<template>
  <div style="width: 100%; height: 100%; min-height: 230px; max-height: 300px">
    <highcharts v-if="chartDatas" :options="chartOptions" v-bind:style="{ height: '100%'}"></highcharts>
    <div v-else class="text-center pb-40" v-bind:style="{backgroundColor:'#F7F3FE'}">
      <span v-if="loading" class="spinner farm-chart-spinner" style="top: 70px;"></span>
      <span v-else style="top: 70px;"><br><br><br>{{ i18n.noData }}</span>
    </div>
  </div>
</template>

<script>
import {Chart} from 'highcharts-vue'
import {
  getRefinedHighchartSeriesData,
} from '@/core/services/funcs.js';
import i18n from '@/core/plugins/vue-i18n.js';


export default {
  name: 'MonitoringAlertTimeChartWidget',
  components: {
    highcharts: Chart
  },
  props: {
		chartDatas: Object,
    fno: Number,
    systemId: String,
    startYmd: String,
    endYmd: String,
    mutateValue: Function,
    pieChartDatas: Array
  },
  data(){
    return{
      i18n: {
        management: i18n.t('farm.alertLogTimeSeries.management'),
        abnormalAlarm: i18n.t('farm.alertLogTimeSeries.abnormalAlarm'),
        physiologicalDisorder: i18n.t('farm.alertLogTimeSeries.physiologicalDisorder'),
        deviationCount: i18n.t('farm.alertLogTimeSeries.deviationCount'),
        noData: i18n.t('farm.alertLogTimeSeries.noData')
      },
      loading: false,
    }
  },
  computed: {
    chartOptions(){
      const options = {
        chart: {
          zoomType: 'x,y',
          backgroundColor: '#F7F3FE'
        },
        title: {
          text: '<span class="font-weight-bold float-right"></span>'
        },
        yAxis: {
					title: {
						text: `${this.i18n.deviationCount}`
					}
				},
        xAxis: {
          type: 'datetime',
          title: '일자',
          labels: {
            format: '{value:%y.%m.%d}',
          },
        },
        credits: {
          enabled: false
        },
        tooltip: {
					valueDecimals: 1,
					shared: true
				},
        series:[
          {
            type: 'pie',
            name: `${this.i18n.deviationCount} (%)`,
            data: [{
              name: `${this.i18n.management}`,
              y: this.pieChartDatas[0]/this.pieChartDatas.reduce(function add(sum, currValue){return sum + currValue}, 0)*100,
              color :'#FCCECA',
            }, {
              name: `${this.i18n.abnormalAlarm}`,
              y: this.pieChartDatas[1]/this.pieChartDatas.reduce(function add(sum, currValue){return sum + currValue}, 0)*100,
              color: '#E8A0BF',
            }, {
              name: `${this.i18n.physiologicalDisorder}`,
              y: this.pieChartDatas[2]/this.pieChartDatas.reduce(function add(sum, currValue){return sum + currValue}, 0)*100,
              color: '#C47FC7',
            }],
            center: [10, 10],
            size: 50,
            showInLegend: false,
            dataLabels: {
              enabled: false
            }
          }
        ],

        plotOptions: {
          column: {
            stacking: 'normal',
          },
          series: {
            turboThreshold: 10000
          }
        },
			};

			options.yAxis.min =  0
			options.yAxis.max =  1500

      Object.values(this.chartDatas).map(data => {
        const refinedSeriesData = getRefinedHighchartSeriesData(data.value)
        const series = {
          name: data.title,
					type: data.chartType,
          color: data.color,
					data: refinedSeriesData,
				};
        options.series.push(series)
			})

      return options;
    }
  },
};
</script>